import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de cervell, amb consistència gelatinosa que forma masses de plecs de fins a 10 cm o més de color groc de color groc molt vistós en temps humit amb diferents tonalitats més o menys clares en temps més sec. Les espores són blanques, el·lipsoidals, de 7-10 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      